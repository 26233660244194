<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>管理中心</a-breadcrumb-item>
    <a-breadcrumb-item>{{ (types.find(item => item.type === type) || {}).name || '-' }}</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 隐藏停止合作的供应商 -->
    <a-checkbox v-model:checked="checked" @change="changeChecked">隐藏停止合作的供应商</a-checkbox>
    <!-- 操作 -->
    <div style="flex: 1;"></div>
    <a-button type="primary" style="margin-left: 16px;" @click="touchAdd"><plus-outlined /> 新建</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 合作状态 -->
      <template v-if="column.key === 'friendship_status'">
        {{ record[column.key] === 1 ? '合作中' : '停止合作' }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="touchEdit(record)">编辑</a>
      </template>
    </template>
  </a-table>
  <!-- 新建/编辑 -->
  <NewSupplierDrawer
    :type="type"
    ref="RefNewSupplierDrawer"
    @success="getSupplierList"
  >
  </NewSupplierDrawer>
</template>

<script setup>
import { onBeforeMount, reactive, ref } from 'vue'
import NewSupplierDrawer from './NewSupplierDrawer'
import { supplierList } from '@/api/copyright'

// props
const props = defineProps({
  type: Number
})
// 新建/编辑
let RefNewSupplierDrawer = ref(null)
// 隐藏停止合作的供应商
let checked = ref(true)
// 加载
let isLoading = ref(false)
// types供应商类型数组
const types = ref([
  {
    type: 1,
    name: '摄制供应商'
  },
  {
    type: 2,
    name: '剧本供应商'
  },
  {
    type: 3,
    name: '成片供应商'
  },
])
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '供应商ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '供应商名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '累计签约',
    dataIndex: 'drama_count',
    key: 'drama_count'
  },
  {
    title: '在约数量',
    dataIndex: 'drama_friendship',
    key: 'drama_friendship'
  },
  {
    title: '合作状态',
    dataIndex: 'friendship_status',
    key: 'friendship_status'
  },
  {
    title: '入库日期',
    dataIndex: 'entry_time',
    key: 'entry_time'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

onBeforeMount(() => {
  // 获取列表
  getSupplierList()
})

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  // 获取列表
  getSupplierList()
}

// 切换隐藏状态
function changeChecked () {
  pagination.current = 1
  // 获取列表
  getSupplierList()
}

// 新建
function touchAdd () {
  RefNewSupplierDrawer.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefNewSupplierDrawer.value.showDrawer(record)
}

// 获取列表
function getSupplierList () {
  isLoading.value = true
  const params = {
    type: props.type,
    friendship_status: Number(checked.value),
    page: pagination.current,
    page_size: pagination.pageSize
  }
  supplierList(params).then(res => {
    isLoading.value = false
    const { code, data } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    }
  }).catch(() => {
    isLoading.value = false
  })
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>